import { base, baseSepolia } from "thirdweb/chains";
import { createThirdwebClient } from "thirdweb";
import configuration from "Config";
const initialState = {
	isAuthenticated: false,
	newAuthFlowUserDetails: {},
	bullTokenPrice: 0,
	ethTokenPrice: 0,
	userDetails: {},
	userAccountDetails: null,
	userWalletBalance: null,
	factoryAddress: configuration.FACTORY_ADDRESS,
	ERC20Contract: null,
	ERC20ContractAddress: configuration.ERC20ContractAddress,
	defaultChainId: Number(configuration.defaultChainId),
	polygonChainId: Number(configuration.polygonChainId),
	defaultChain: configuration.REACT_APP_ENV === "DEV" ? baseSepolia : base,
	blockExplorer: configuration.REACT_APP_ENV === "DEV" ? baseSepolia.blockExplorers[0].url : base.blockExplorers[0].url,
	clientId: configuration.THIRD_WEB_CLLIENT_ID,
	client: createThirdwebClient({
		clientId: configuration.THIRD_WEB_CLLIENT_ID,
	}),
	allSessionKeys: [],
	allSigners: [],
	USDCTokenAddress: configuration.USDCTokenAddress,
	USDCContract: null,
	ACTIVATING_SESSION_KEY: false,
	USER_POINTS: {},
	web3Provider: null,
	sepolia_INFURA_URL: configuration.sepolia_INFURA_URL[Math.floor(Math.random() * configuration.sepolia_INFURA_URL.length)],
};

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case "LOAD_USER_DETAILS":
			localStorage.setItem("isUserLoggedIn", action.payload.status);
			return {
				...state,
				userDetails: action.payload,
			};
		case "LOAD_USER_POINTS":
			return {
				...state,
				USER_POINTS: action.payload,
			};
		case "LOAD_USER_ACCOUNT_DETAILS":
			return {
				...state,
				userAccountDetails: action.payload,
			};
		case "LOAD_USER_WALLET_BALANCE":
			return {
				...state,
				userWalletBalance: action.payload,
			};
		case "LOAD_USER_SESION_KEYS":
			return {
				...state,
				allSessionKeys: action.payload,
			};
		case "ACTIVATING_SESSION_KEY":
			return {
				...state,
				ACTIVATING_SESSION_KEY: action.payload,
			};
		case "LOAD_USER_ALL_SIGNER":
			return {
				...state,
				allSigners: action.payload,
			};
		case "LOAD_ERC20Contract":
			return {
				...state,
				ERC20Contract: action.payload,
			};
		case "LOAD_USDCContract":
			return {
				...state,
				USDCContract: action.payload,
			};
		case "addNewAuthFlowUserDetails":
			return {
				...state,
				newAuthFlowUserDetails: action.payload,
			};
		case "SET_BULL_TOKEN_PRICE":
			return {
				...state,
				bullTokenPrice: action.payload,
			};
		case "SET_ETH_TOKEN_PRICE":
			return {
				...state,
				ethTokenPrice: action.payload,
			};
		case "SET_WEB3_PROVIDER":
			return {
				...state,
				web3Provider: action.payload,
			};
		default:
			return state;
	}
}
